import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import './carousel.css';

//images
import slide1 from '../../assets/images/slider1.jpg';
import slide2 from '../../assets/images/slider2.jpg';
import slide3 from '../../assets/images/slider3.jpg';
import slide4 from '../../assets/images/slider4.jpg';
import slide5 from '../../assets/images/slider5.jpg';

const Carousel = () => {
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplayspeed: 1000,
        variableWidth: true,
        centerMode: true
      };

    return (
        <div className="slider-container" >
            <div className="wrapper">
                <Slider {...settings}>
                    <div className="slide"><img src={slide1} alt="Sanskiti Ramika" /></div>
                    <div className="slide"><img src={slide2} alt="Sanskoo With Protea poster" /></div>
                    <div className="slide"><img src={slide3} alt="Group in school" /></div>
                    <div className="slide"><img src={slide4} alt="Sanskriti" /></div>
                    <div className="slide"><img src={slide5} alt="Sanskriti" /></div>
                </Slider>
            </div>
        </div>
    )
}

export default Carousel;