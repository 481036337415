import './events.css';
import {Fragment} from 'react';


import openmicgroup from '../../assets/events/openmicgroup.jpg';
import openmic1 from '../../assets/events/openmic alisha khatri.jpg';
import openmic2 from '../../assets/events/openmic aryan kamal.jpg';
import openmic3 from '../../assets/events/openmic ayusha lamicchhane.jpg';
import openmic4 from '../../assets/events/openmic jasmine sharma.jpg';
import openmic5 from '../../assets/events/openmic niraj kc.jpg';
import openmic6 from '../../assets/events/openmic sanskriti pokhrel.jpg';
import openmic7 from '../../assets/events/openmic smarika pandey.jpg';
import openmic8 from '../../assets/events/openmic swikriti mishra.jpg';

// March Awareness program
import sanskoofull from '../../assets/events/awareness-mar-23/sanskriti with certificate.jpg'
import sanskoo from '../../assets/events/awareness-mar-23/sanskriti.jpg'
import sanskoo2 from '../../assets/events/awareness-mar-23/sanskriti 2.jpg'

// first image should be the group or full width...
const pastEventImages = {
    openmic: [openmicgroup, openmic1, openmic2, openmic3, openmic4, openmic5, openmic6, openmic7, openmic8],
    awarenssMarch23: [sanskoofull, sanskoo, sanskoo2]
}

const events = [
    {
        id: 1,
        month: "Feb'23",
        description: "Open mic session",
        images: pastEventImages.openmic
    },
    {
        id: 2,
        month: "Mar'23",
        description: "LGBTQ+ Awareness program",
        images: pastEventImages.awarenssMarch23
    }
]

const upcomingEvents = [
    {
        id: 1,
        month: "July'24",
        description: "SPI-RT by Protea",
    }
]


const Events = () => {
    return(
        <div id="events" className="container">
            <div className="wrapper">
                <div className="text">
                    <div className="text-dancing">Events</div>
                    <h1 className="text-large" style={{marginBottom: "2rem"}}>Join us at upcoming events</h1>
                </div>
                <div className="events">
                    {
                        upcomingEvents.map(event =>
                            <Fragment key={event.id}>
                                <div className="month">{event.month}</div>
                                <div className="details">
                                    {event.description}
                                </div>
                            </Fragment> 
                        )
                    }
                    <div></div> {/* Leave blank */}
                    <div className="bottom-dotted-line">
                        <a href="mailto:contact@proteally.org">Contact us</a>&nbsp; for more details...
                    </div>
                </div>
                
                <div className="text">
                    <h1 className="text-large" style={{marginBottom: "2rem", marginTop: "2rem"}}>Past events</h1>
                </div>
                <div className="past-events">
                    {
                        events.map(event =>
                            <Fragment key={event.id}>
                                <div className="details">
                                <div className="month">{event.month}</div>
                                    <p className='description-text'>{event.description}</p>
                                    {
                                        event.images?
                                        <div className='images'>
                                            {event.images.map((image, i) => <img key={i} src={image} alt={event.description + ' images'}></img>)}
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </Fragment> 
                        )
                    }
                    <div></div> {/* Leave blank */}
                    <div className="bottom-dotted-line">
                    </div>
                </div>
                {/* <div style={{backgroundColor: '#ff0', marginTop: '2rem', color: '#000', fontWeight: 'bold', fontSize: '1rem', textAlign: 'center', padding: '1rem'}}>Upcoming Events will be listed soon ...</div> */}
            </div>
        </div>
    )
}


export default Events;