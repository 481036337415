import facebook from '../assets/facebook.png';
import facebookYellow from '../assets/facebook-yellow.png';
import instagram from '../assets/instagram.png';
import instagramYellow from '../assets/instagram-yellow.png';
import mail from '../assets/mail.png';
import mailYellow from '../assets/mail-yellow.png';

export const links = [
    {
        "name": "Home",
        "link": "/"
    },
    {
        "name": "Our-mission",
        "link": "/our-mission"
    }, 
    {
        "name": "Events",
        "link": "/events"
    },
    {
        "name": "Team",
        "link": "/team"
    },
    {
        "name": "Contact",
        "link": "/contact"
    }
]


export const social = [
    {
        "name": "Facebook",
        "icon": facebook,
        "iconYellow": facebookYellow,
        "link": "https://www.facebook.com/Proteally/",
        "text": "/proteally"
    },
    {
        "name": "Instagram",
        "icon": instagram,
        "iconYellow": instagramYellow,
        "link": "https://www.instagram.com/proteally/",
        "text": "/proteally"
    },
    {
        "name": "Email",
        "icon": mail,
        "iconYellow": mailYellow,
        "link": "mailto:contact@proteally.org",
        "text": "contact@protally.org"
    }
]