import "./footer.css"
import logo from './../../assets/protea-black.svg';
import facebook from "../../assets/facebook.png";
import instagram from "../../assets/instagram.png";
import mail from "../../assets/mail.png";
import Social from "../Social/Social";
import { links } from "../../data/links";
import JoinUs from "../Buttons/JoinUs";

import { Link } from "react-router-dom";

const Footer = () => {
    function scrollToTop(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <footer className="container">
            <div className="wrapper">
                <div className="logo">
                    <Link onClick={scrollToTop} to="/"><img src={logo} alt="Protea logo"/></Link>
                </div>
                {/* decorative */}
                <div className="line"></div>
                {/* --------------------- */}
                <Social bgColor="black" />

                <nav>
                    <ul>
                        {links.map((link) => 
                                <li onClick={scrollToTop} key={link.name}><Link to={link.link}>{link.name}</Link></li>
                        )}
                    </ul>
                </nav>
                <div className="cta-btns">
                    <JoinUs />
                </div>
                <hr />
                <div className="copy">Copyright &copy; {new Date().getFullYear()} | Protea</div>
            </div>
        </footer>
    )
}

export default Footer;