import sanskriti from '../assets/profile/sanskriti.webp'
import abhishekh from '../assets/profile/abhishekh.webp'

const founders = [
    {
        name: "Sanskriti Duwadi",
        image: sanskriti,
        desig: "Founder, Executive Director",
        email: "sanskriti@proteally.org",
        description: "Sanskriti Duwadi is a writer, classical dancer, theatre artist, and aspiring actor. She also serves as a Global Peace Ambassador and was crowned Miss Teen Universe Nepal 2022. She gained national recognition as a gender activist and was honored as a 20 Under 20 Glocal Teen Hero Nepal 2022. Sanskriti is the first woman light operator through the Nomad application in Nepal. Additionally, she founded 'The Wordsworthers', a community for literature enthusiasts who believe in the motto \"Since the soul is our author, we live immortal\". Sanskriti channels her artistic creations for the betterment of humanity and believes that \"We all belong to one community, and that is Humanity\"."
    },
    {
        name: "Abhishekh Sharma Rajopadhyaya",
        image: abhishekh,
        desig: "Managing Director",
        email: "abhishekh@proteally.org",
        description: "Abhishekh Sharma Rajopadhyaya is a priest, entrepreneur, and artist with a vision to become a humanitarian. He holds a BBA degree from JK Business School in India and has also graduated from Mandala Theatre Nepal as a theatre artist. He has been involved in several plays and social projects, working as a manager. His aspiration is to expand his entrepreneurial endeavors while serving humanity. His mantra is \"Om Namah Bhagvate Vasudevaya\"."
    }
]


export default founders;