import './social.css';
import { social } from '../../data/links';

const Social = ({bgColor}) => {
    return (
        <div className="social">
            { social.map((link) => 
                <a className='anim-social' key={link.name} href={link.link} target="_blank" rel="noreferrer"><img src={bgColor==="black" ? link.iconYellow: link.icon} alt={link.name} /></a>
            )}
        </div>
    )
}

export default Social ;