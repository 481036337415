import "./menu.css";
import "./header.css";
import logo from './../../assets/protea.svg';
import { useRef } from "react"
import { links } from "../../data/links";
import Social from "../Social/Social";
import {NavLink , Link} from 'react-router-dom';

const Header = () => {
    const headerRef = useRef(null);
    const menuTogglerRef = useRef(null);
    
    function toggle(){
        menuTogglerRef.current.classList.toggle('close'); //change menu icon
        headerRef.current.classList.toggle('shown'); //show the navbar 
    }

    function scrollToTop(){
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    return (
        <header className="container" ref={headerRef}>
            <div className="wrapper">
                <div className="logo">
                    <Link onClick={scrollToTop} to="/"><img src={logo} alt="Protea logo"/></Link>
                </div>
                <div className="header-switches">
                    <div id="menu-toggler" ref={menuTogglerRef} onClick={toggle}>
                        <span className="line"></span><span className="line"></span><span className="line"></span>
                    </div>
                </div>
                <nav id="main-nav">
                    <div className="wr">
                        <div className="links">
                            <ul>
                                {links.map((link) => 
                                    <li onClick={()=>{toggle(); scrollToTop();}} key={link.name}><NavLink to={link.link}>{link.name}</NavLink></li>
                                )}
                            </ul>
                        </div>
                        <Social />
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;
