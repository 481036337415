import './profile.css';


// pass:
// data.name
// data.image
// data.desig
// data.description

const Profile =  ({data}) => {
    return (
        <div className="profile">
            <div className="image">
                <img src={data.image} alt={data.name} />
            </div>
            <div className="details">
                <div className="name">{data.name}</div>
                <div className="desig">{data.desig}</div>
                <div className="info">{data.description}</div>
            </div>
        </div>
    )
}


export default Profile;