import Profile from '../Profile/Profile';
import founders from '../../data/founders';

const Team = () => {
    return(
        <div className='team container'>
            <div className="wrapper">
                <div className="head" style={{marginBottom: "1rem"}}>
                    <div className="text-dancing">Team</div>
                    <h1 className="text-large">Meet our team</h1>
                </div>
                <div className="profiles">
                    {
                        founders.map(founder => <Profile key={founder.name} data={founder} />)
                    }
                </div>
            </div>
        </div>
    )
}

export default Team;