import heroflag from '../../assets/images/heroflag.jpg'
import JoinUs from '../Buttons/JoinUs';
import './hero.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef, useLayoutEffect } from 'react';

const Hero = () => {
    const heroRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    
    useLayoutEffect(() => {
        let ctx = gsap.context (()=>{
            gsap.set(".anim-hero-image",{
                y: -100,
                opacity: 0,
                clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)"
            })

            gsap.set(".anim-hero",{
                y: 100,
                opacity: 0,
                clipPath: "polygon(0% 100%, 99% 100%, 100% 100%, 0% 100%)"
            })

            gsap.to(".anim-hero-image", {
                y: 0,
                opacity: 1,
                clipPath: "polygon(0% 100%, 99% 100%, 100% 0%, 0% 0%)",
                duration: 1.2,
                stagger: .3,
            })

            gsap.to(".anim-hero", {
                scrollTrigger: '.anim-hero',
                y: 0,
                opacity: 1,
                clipPath: "polygon(0% 100%, 99% 100%, 100% 0%, 0% 0%)",
                duration: 1.2,
                stagger: .2,
            })

        }, heroRef)

        return () => ctx.revert(); 

    }, [])

    return (
        <div ref={heroRef} className="hero container">
            <img className='anim-hero-image' src={heroflag} alt="LGBTQ flag" />
            <div className="wrapper text-center focus-content">
                <div className="text-dancing anim-hero">Rainbow reign:</div>
                <h1 className="text-large anim-hero">SHINNING COLLECTIVELY </h1> 
                <h1 className='text-large anim-hero text-outline'>AS WHITE LIGHT</h1>
                <p className="text-info anim-hero" style={{lineHeight: "2"}}>
                Protea is an organisation that is dedicated to
                propel world peace with youth development.
                </p>
                <div className="anim-hero">
                    <JoinUs className="join-us" text="Become a member" />
                </div>

            </div>
        </div>
    )
}


export default Hero;