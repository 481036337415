import './button.css';

const JoinUs = ({text}) => {
    return (
        <div className='join-us'>
            <a href="https://forms.gle/Kn97cyuoHxnTtPeJ8" target="_blank" rel="noreferrer" className="button-filled anim-attention">{ text || "Join Us" }</a>
        </div>
    )
}


export default JoinUs;