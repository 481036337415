import JoinUs from "../Buttons/JoinUs";
import './footercta.css';

const FooterCTA = () => {
    return (
        <div className="footer-cta container">
            <div className="wrapper focus-content">
                <p className="text-info">
                    This is a call to every person out there who wishes to see prosperity and togetherness in our country. We Nepalese, who have chanted "United we stand, divided we fall" since centuries, must join hands in creating internal peace for development externally.
                </p>
                <p className="text-info text-cta">
                    Interested to <span>join this revolution?</span>
                </p>
                <JoinUs />
            </div>

        </div>
    )
}

export default FooterCTA